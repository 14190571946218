body{
    background: linear-gradient(104.12deg, rgba(10, 30, 65, 0.9) -10.99%, rgba(0, 161, 155, 0.9) 112.9%), url(../../static/banner_login.jfif);
    background-repeat:no-repeat;
    background-size: cover;
  }
  
  .container {
    height: 100vh;
  }
  
  .card {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(0.5px);
    border-radius: 20px;
    border: none;
    min-height: 80%;


  }
  
  .card .ant-card-body {
    height: 100%;
  }
  
  .span-input {
    color:#fff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  
  .input-login {
    background: rgba(239, 239, 255, 0.1);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    height: 56px;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .input-login .ant-input {
    background: transparent;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .span-Link, .link a {
    color: #fff;
  }
  
  .span-Link a:hover {
    text-decoration: underline;
  }
  
  .submit {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 56px;
    width: 100%;
    color: #0A1E41;
    font-size: 16px;
    font-weight: 700;
    border: none
  }
  
  .submit:hover{
    background: #0A1E41;
    opacity: 0.8;
  }
  
  .title {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
  }
  
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: #fff;
  }
  
  .arrow {
    position: absolute;
    border: none;
    color: #fff;
    top: 7%; 
    left: 0;
  }
  
  .link {
    text-decoration: underline;
    color: white;
  }
  
  .cards {
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 5px;
    background: rgba(239, 239, 255, 0.1);
    padding: 16px 46px;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    cursor: pointer;
 
  }
  
  .cards:hover {
    background: #0A1E41;
    filter: drop-shadow(0px 8.25005px 3.00002px rgba(31, 37, 46, 0.02)) drop-shadow(0px 4.50003px 3.00002px rgba(31, 37, 46, 0.08)) drop-shadow(0px 2.25001px 2.25001px rgba(31, 37, 46, 0.13)) drop-shadow(0px 0.750005px 1.50001px rgba(31, 37, 46, 0.15)) drop-shadow(0px 0px 0px rgba(31, 37, 46, 0.15)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  }
  
  .active {
    background: #0A1E41;
  filter: drop-shadow(0px 8.25005px 3.00002px rgba(31, 37, 46, 0.02)) drop-shadow(0px 4.50003px 3.00002px rgba(31, 37, 46, 0.08)) drop-shadow(0px 2.25001px 2.25001px rgba(31, 37, 46, 0.13)) drop-shadow(0px 0.750005px 1.50001px rgba(31, 37, 46, 0.15)) drop-shadow(0px 0px 0px rgba(31, 37, 46, 0.15)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  }
  
  .title {
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 500;
    font-size: 21px;
    line-height: 29px;
    margin-bottom: 16px;
  }
  
  .label {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: white;
  }
  
  .label-cnpj {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    color: white;
  }
  
  .companies-list {
    max-height: 350px;
    overflow: auto;
  }
  
  @media (min-width:319px) and (max-width: 414px){
    .content-login {
      width: 95%;
      height: 95%;
    }
  }
  @media (min-width:415px) and (max-width: 533px){
    .content-login {
      width: 75%;
    }
  }
  @media (min-width: 534px) and (max-width: 575px){
    .content-login {
      width: 70%;
    }  
  }
  @media (min-width: 576px) and (max-width:744px){
    .content-login {
      width: 65%;
    }
  }
  @media (min-width: 1500px) and (max-width: 767px){
    .content-login {
      width: 70%;
    }
  }
  @media (min-width: 1500px) and (max-width: 991px){
    .content-login {
      width: 70%;
    }  
  }
.table-value{
    width: 100%;
}
.link-detail-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 16px;
   
}

.link-detail-card-describe-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0A1E41;
}

.link-detail-card-title {
    font-size: 18px;
    font-weight: 500;
}

.link-detail-card-describe {
    font-size: 24px;
    font-weight: 700;
}

.link-detail-card-img {
    margin-top: 6px;
    width: 80px;
}

.link-detail-content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    margin-top: 24px;
}

.link-detail-content-subtitle {
    color: #0A1E41;
    font-weight: 500;
    font-size: 24px;
}

.link-detail-content-ranking {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
}

.link-detail-content-ranking-bar-chart {
    height: 450px;
}

.link-detail-content-ranking-summary-content {
    padding: 0px 40px 40px;
}

.link-detail-content-ranking-summary-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    padding: 0px 40px;
}

.link-detail-content-ranking-summary-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E3E6E9;
    border-radius: 4px;
}

.link-detail-content-ranking-summary-item-text {
    color: #707070;
    opacity: 0.6;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.link-detail-content-ranking-summary-item-value {
    color: #4B4B4D;
    font-weight: 500;
    font-size: 14px;
}

.link-detail-content-ranking-summary-item-span {
    position: relative;
    top: 15px;
    left: 5px;
    font-weight: 600;
    font-size: 24px;
}

@media (min-width:500px) and (max-width: 800px) {
    .link-detail-card-group {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 10px;
    }

    .link-detail-card-title {
        font-size: 13px;
    }

    .link-detail-card-describe {
        font-size: 18px;
    }

    .link-detail-card-img {
        margin-top: 0px;
        width: 50px;
    }

    .link-detail-content-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .link-detail-content-ranking-summary-content {
        padding: 0;   
    }

    .link-detail-content-ranking-summary-grid {
        grid-template-columns: repeat(1, 1fr);
        padding: 0px 40px 30px;
        column-gap: 0
        
    }
}