.page-header-tab.ant-tabs-top > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-top > div > .ant-tabs-nav::before, 
.page-header-tab.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #C4C4C4;
}

.home-card-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    margin-top: 12px;
}

.home-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 16px;
}
.transactions-filter-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: end;
    margin: 32px;
}

.transactions-filter-menu{
    margin-top: 32px;
    position: absolute;
    padding: 32px;
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 8px;
    background-color: #C4C4C4;
    border-radius: 5px;
    z-index: 1000;
}

.custom-checkbox-wrapper .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    border-radius: 50% !important; /* Faz o checkbox ficar redondo */
  }
  
  .custom-checkbox-wrapper .ant-checkbox-checked::after {
    border: 1px solid transparent !important;
  }
  
  .custom-checkbox-wrapper .ant-checkbox {
    top: 0;
  }

.home-card-describe-content > span {
    display: block; 
    padding-top: 4px; 
    padding-bottom: 4px; 
  }

.transactions-filter-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #ffffff;
    border-color: #ffffff;
    color: #000000; 
}

.transactions-filter-button:hover {
    background-color: #e0e0e0; 
    color: #333333; 
    cursor: pointer; 
}

.transactions-button {
    float: right;
    border: 1.5px solid #ffffff;
    border-radius: 5px;
    color: #0a1e41;
}

.transactions-button:hover {
    background: #C4C4C4;
    color: white;
    border-color: white;
}

.transactions-button:active {
    background: #C4C4C4;
    color: white;
    border-color: white;
}
.ant-btn-default.transactions-button {
    background-color: #ffffff !important; /* Nova cor de fundo */
    color: white !important; /* Nova cor do texto */
    border-color: #ffffff !important; /* Nova cor da borda */
  }

.ant-btn-primary.transactions-button {
    background-color: #C4C4C4 !important; /* Nova cor de fundo */
    color: white !important; /* Nova cor do texto */
    border-color: #C4C4C4 !important; /* Nova cor da borda */
  }

.transactions-summary-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    margin: 30px 0px;
}

.transactions-summary-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 25px 0px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.transactions-summary-item-title {
    font-weight: 500;
    font-size: 17px;
    color: #000000;
}

.transactions-summary-item-subtitle {
    font-weight: 500;
    font-size: 14px;
    color: #666666;
}

.transactions-summary-item-value {
    font-weight: 600;
    font-size: 22px;
    color: #666666;
}

.transactions-summary-item-fail {
    margin-top: 18px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FF0F0F;
}

@media (max-width:991px){
    .transactions-table-content {
        overflow-x: scroll;
    }

    .transactions-summary-container {
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
    }

    .transactions-summary-item-title {
        font-size: 13px;
    }

    .transactions-summary-item-value,
    .transactions-summary-item-fail {
        font-size: 18px;
    }

    .transactions-summary-item-subtitle {
        font-size: 14px;
    }

}