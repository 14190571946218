.bank-slip-payments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bank-slip-payments-content {
    width: 60%;
}

.bank-slip-payments {
    margin-top: 20px;
}

.bank-slip-payments-item {
    margin: 10px 0px;
}

.bank-slip-payments-forms {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
}

.bank-slip-payments-button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}

.bank-slip-payments-span {
    font-weight: 600;
    color: #0A1E41;
    font-size: 14px;
    text-align: center;
    margin-bottom: 25px;
}

.bank-slip-payments-describe-content {
    z-index: -1;
    margin-top: -15px;
    padding-top: 25px;
    padding-bottom: 10px;
    border-radius: 0px 0px 10px 10px;
    background-color: #0A1E41;
    color: white;
}

.bank-slip-payments-balance-button-title-content {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.bank-slip-payments-balance-button-title {
    font-size: 18px;
    font-weight: 400;
}

.bank-slip-payments-balance-button-value {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
}

.bank-slip-payments-balance-button-summary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.bank-slip-payments-balance-button-summary-content {
    width: 70%;
}

.bank-slip-payments-balance-button-summary-item {
    display: flex;
    justify-content: space-between;
}

.bank-slip-payments-balance-button-summary-title {
    font-weight: 600;
    color: white;
}

.bank-slip-payments-balance {
    border: 2px solid #00A19B;
    border-radius: 5px;
    padding: 10px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #494949;
}

.bank-slip-payments-balance-button-content {
    display: flex;
    align-items: center;
}

.bank-slip-payments-balance-button {
    border-radius: 50%;
    background: transparent;
    border: none;
    margin-left: 10px;
}
.payments-link-receipt-button-content {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}