.tax-group-container {
    margin: 6px 0px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
}

.tax-group-content-installment {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    grid-auto-flow: row;
    column-gap: 12px;
    row-gap: 12px;
}

.tax-group-content-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-div {
    display: flex;
    gap:16px;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media (max-width:991px){
    .clients-content {
        overflow-x: scroll;
    }

    .clients-filter-content {
        grid-template-columns: 1fr;
        row-gap: 10px;
        width: 100%;
    }
}