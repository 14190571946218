.voucher-container {
    margin-top: 20px;
}

.voucher-filter-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
}

.voucher-button-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.voucher-filter-button {
    background: #D9D9D9;
    border-radius: 5px;
    padding: 10px 21px;
    height: auto;
    min-width: 76px;
    margin: 10px;
    border: 3px solid transparent;
}

.value-filter-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.voucher-filter-button:hover,
.voucher-filter-button:active,
.voucher-filter-button:focus,
.voucher-filter-button-active {
    background: #D9D9D9;
    border: 3px solid #00A19B;
    color: black;
}

.certificates-filter-content {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    justify-content: center;
}

.search-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.custom-input .ant-input {
    background-color: transparent;
}