.tax-table table {
    border-collapse: collapse; 
    table-layout: auto;
}

.tax-table .ant-table-body {
    padding-right: 5px;
    padding-bottom: 10px;
}

.tax-table .ant-table{
    background: transparent;
}

.tax-table tr.ant-table-row {
    border-top: 10px solid transparent;
    height: 1px;
    cursor: default;
}

.tax-table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0;
    height: inherit;
}

.tax-table-container {
    margin-top: 10px;
}

.tax-table-cell {
    background-color: #fff;
    padding: 2%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 5px 3px 4px rgb(0 0 0 / 25%);
    z-index: 1;
    justify-content: center;
}

.button-tax-col{
    width: 40;
}

.tax-table-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
}

.tax-table-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #636363;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tax-table-description {
    background: #0A1E41;
    margin-top: -10px;
    padding: 25px 40px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tax-table-description-title {
    margin: 0;
    margin-bottom: 10px;
    color: white;
}

.tax-table-description-value {
    color: white;
}

.tax-table-status {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.tax-table-status .tax-table-value {
    font-weight: 600;
}

.tax-table-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.tax-table-aprove {
    background: #00C108;
    color: white;
    font-weight: 600;
    border-color: transparent;
    margin-right: 15px;
}

.tax-table-aprove:hover,
.tax-table-aprove:active,
.tax-table-aprove:focus {
    background: #00C108;
    opacity: 0.8;
    color: white;
    font-weight: 600;
    border-color: transparent;
}

.tax-table-refuse {
    background: #FF0000;
    color: white;
    font-weight: 600;
    border-color: transparent;
    margin-right: 15px;
}

.tax-table-refuse:hover,
.tax-table-refuse:active,
.tax-table-refuse:focus {
    background: #FF0000;
    opacity: 0.8;
    color: white;
    font-weight: 600;
    border-color: transparent;
}

.tax-group-action-button{
    width: 99%;
}

@media (min-width:319px) and (max-width: 414px){
    .tax-table-container {
        width: 100%;
        overflow-x: scroll;
    }

    .tax-table table {
        table-layout: fixed !important;
    }
}

@media (min-width:415px) and (max-width:744px){
    .tax-table-container {
        width: 100%;
        overflow-x: scroll;
    }

    .tax-table table {
        table-layout: fixed !important;
    }
}

