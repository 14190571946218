.pix-deposit-result {
    margin-top: 20px;
}

.payment-link-result-title {
    color: #fcfdfd;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.pix-deposit-result-content {
    display: block;
    margin-top: 20px;
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.pix-deposit-result-content-qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pix-deposit-result-describe-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 37px;
}

.pix-deposit-result-describe-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #494949
}

.pix-deposit-result-describe-value {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #00A19B
}

.pix-deposit-result-copy {
    display: flex;
    flex-direction: column;
}

.pix-deposit-result-copy-code-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pix-deposit-result-copy-code {
    max-width: calc(190px - 32px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.pix-deposit-result-resume {
    display: flex;
    flex-direction: column;
    border: 1px solid #00A19B;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.pix-deposit-result-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.pix-deposit-result-button {
    background: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;
}

.pix-deposit-result-button:hover {
    color: #00A19B
}


@media (max-width: 1160px) {
    .pix-deposit-result-content {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
}